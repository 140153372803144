import { useRequest } from 'ahooks';
import { Button, Checkbox, Form, Input, Select, Typography } from 'antd';
import { useState } from 'react';
import { useFetcher } from 'rest-hooks';

import { MaskedInput, Warning } from '@/components';
import { GLOBAL_FORM_TYPE } from '@/helpers/constants';
import { AuthResource, ChangePasswordResource, UserResource } from '@/resources';

require('./styles.less');

const { Option } = Select;
const { Title } = Typography;

const { ROLE: REGISTER_TYPE } = UserResource;

const emailPlaceholder = 'ivan@ivanov.net';

const SignFormContainer = (props) => {
  const { className, form, setLoading } = props;
  const [selectedForm, setForm] = useState(form);

  const formProps = {
    className,
    layout: 'vertical',
    hideRequiredMark: true
  };

  const changeForm = (type) => () => {
    setForm(type);
  };

  if ((form === GLOBAL_FORM_TYPE.SIGN_UP && !selectedForm) || selectedForm === GLOBAL_FORM_TYPE.SIGN_UP) {
    return <SignUpForm formProps={formProps} onFormChange={changeForm} setLoading={setLoading} />;
  }

  if (selectedForm === GLOBAL_FORM_TYPE.REMIND_PASSWORD) {
    return <RemindPasswordForm formProps={formProps} onFormChange={changeForm} setLoading={setLoading} />;
  }

  return <SignInForm formProps={formProps} onFormChange={changeForm} setLoading={setLoading} />;
};

const SignInForm = ({ formProps, onFormChange, setLoading }) => {
  const signIn = useFetcher(AuthResource.createShape(), true);

  const handleFinish = async (formValues) => {
    const { email, remember, password } = formValues;

    const values = {
      email,
      password
    };

    setLoading(true);

    await signIn({ remember }, values);

    setLoading(false);
  };

  return (
    <div className="signin-form">
      <Title align="center">Вход</Title>

      <div className="signin-form__info">
        У вас ещё нет аккаунта?{' '}
        <Button className="signin-form__info-signup" type="text" onClick={onFormChange(GLOBAL_FORM_TYPE.SIGN_UP)}>
          Зарегистрируйтесь
        </Button>
      </div>

      <Form
        {...formProps}
        initialValues={{
          remember: true
        }}
        onFinish={handleFinish}
      >
        <div className="signin-form__fields">
          <Form.Item label="Электронная почта" name="email" rules={[{ required: true, message: 'Введите e-mail' }]}>
            <Input placeholder={emailPlaceholder} />
          </Form.Item>
          <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Введите пароль' }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Запомнить меня</Checkbox>
          </Form.Item>
        </div>

        <div className="signin-form__control">
          <Button className="signin-form__control-submit" size="large" htmlType="submit" type="primary">
            Войти
          </Button>
          <Button
            className="signin-form__control-remind-password"
            type="text"
            onClick={onFormChange(GLOBAL_FORM_TYPE.REMIND_PASSWORD)}
          >
            Забыли пароль?
          </Button>
        </div>
      </Form>
    </div>
  );
};

const SignUpForm = ({ formProps, onFormChange, setLoading }) => {
  const [registerType, setRegisterType] = useState(REGISTER_TYPE.COMPANY);
  const signUp = useRequest(UserResource.createShape().fetch, {
    manual: true,
    onSuccess: () => setLoading(false),
    onError: () => setLoading(false)
  });

  const onFinish = (formValues) => {
    const { contactName, email, inn, name, newsSubscription, password } = formValues;

    const values = {
      name,
      email,
      password,
      role: registerType,
      newsSubscription,
      ...(inn ? { inn } : {}),
      ...(registerType !== REGISTER_TYPE.PERSON ? { contactName } : {})
    };

    setLoading(true);

    signUp.run({}, values);
  };

  if (signUp.data) {
    return (
      <div className="signup-form">
        <Title align="center">Регистрация</Title>
        <div className="signup-form__success">
          Вы успешно зарегистрировались. Мы отправили письмо на указанную электронную почту. Пройдите по ссылке в письме
          для подтверждения учётной записи.
        </div>
      </div>
    );
  }

  let nameLabel = 'Название организации';
  let namePlaceholder = 'ООО Альбатрос';

  if (registerType === REGISTER_TYPE.INDIVIDUAL) {
    nameLabel = 'Название ИП';
    namePlaceholder = 'ИП Иванов Иван Иванович';
  } else if (registerType === REGISTER_TYPE.PERSON) {
    nameLabel = 'ФИО';
    namePlaceholder = 'Иванов Иван Иванович';
  }

  return (
    <div className="signup-form">
      <Title align="center">Регистрация</Title>
      <div className="signup-form__info">
        Уже зарегистрированы?{' '}
        <Button className="signup-form__info-signin" type="text" onClick={onFormChange(GLOBAL_FORM_TYPE.SIGN_IN)}>
          Войдите
        </Button>
      </div>
      <Form
        {...formProps}
        initialValues={{
          type: registerType,
          newsSubscription: true
        }}
        onFinish={onFinish}
      >
        <div className="signup-form__fields">
          <Form.Item label="Зарегистрироваться как" name="type">
            <Select onChange={setRegisterType}>
              <Option value={REGISTER_TYPE.COMPANY}>Юридическое лицо</Option>
              <Option value={REGISTER_TYPE.INDIVIDUAL}>Индивидуальный предприниматель</Option>
              <Option value={REGISTER_TYPE.PERSON}>Физическое лицо (кроме ИП)</Option>
            </Select>
          </Form.Item>
          {registerType === REGISTER_TYPE.PERSON && (
            <Warning text="Только юр. лица или ИП могут получать заказы через сайт" inline />
          )}
          <Form.Item label={nameLabel} name="name" rules={[{ required: true, message: 'Пожалуйста, заполните поле' }]}>
            <Input placeholder={namePlaceholder} />
          </Form.Item>
          {registerType !== REGISTER_TYPE.PERSON && (
            <>
              <Form.Item
                label="ИНН"
                name="inn"
                rules={[
                  { required: true, message: 'Пожалуйста, заполните поле' },
                  {
                    min: registerType === REGISTER_TYPE.INDIVIDUAL ? 12 : 10,
                    message: 'Пожалуйста, укажите ИНН в правильном формате'
                  },
                  {
                    max: registerType === REGISTER_TYPE.INDIVIDUAL ? 12 : 10,
                    message: 'Пожалуйста, укажите ИНН в правильном формате'
                  }
                ]}
              >
                <MaskedInput
                  mask={registerType === REGISTER_TYPE.INDIVIDUAL ? '000000000000' : '0000000000'}
                  placeholder={registerType === REGISTER_TYPE.INDIVIDUAL ? '012345678901' : '0123456789'}
                />
              </Form.Item>
              <Form.Item
                label="Контактное лицо"
                name="contactName"
                rules={[{ required: true, message: 'Пожалуйста, заполните поле' }]}
              >
                <Input placeholder="Иванов Иван" />
              </Form.Item>
            </>
          )}
          <Form.Item
            label="Электронная почта"
            name="email"
            rules={[{ required: true, message: 'Пожалуйста, заполните поле' }]}
          >
            <Input placeholder={emailPlaceholder} />
          </Form.Item>
          <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Введите пароль' }]}>
            <Input.Password />
          </Form.Item>
        </div>
        <Form.Item name="newsSubscription" valuePropName="checked">
          <Checkbox>Получать новости сервиса по электронной почте (не чаще раза в месяц)</Checkbox>
        </Form.Item>
        <div className="signup-form__control">
          <Button className="signup-form__control-submit" size="large" htmlType="submit" type="primary">
            Зарегистрироваться
          </Button>
        </div>

        <div className="signup-form__accept">
          Нажимая кнопку «Зарегистрироваться», вы принимаете условия{' '}
          <a href="/documents/rules">пользовательского соглашения</a> и даёте{' '}
          <a href="/documents/consent-to-processing-data">согласие на обработку персональных данных</a>.
        </div>
      </Form>
    </div>
  );
};

const RemindPasswordForm = ({ formProps, setLoading }) => {
  const changePassword = useRequest(ChangePasswordResource.detailShape().fetch, {
    manual: true,
    onSuccess: () => setLoading(false),
    onError: () => setLoading(false)
  });

  const handleFinish = (formValues) => {
    setLoading(true);

    changePassword.run({ id: formValues.email });
  };

  return (
    <div className="remind-password-form">
      <Title align="center">Восстановление пароля</Title>
      <div className="remind-password-form__info" />
      {!changePassword.data && (
        <Form {...formProps} onFinish={handleFinish}>
          <div className="remind-password-form__fields">
            <Form.Item label="Электронная почта" name="email" rules={[{ required: true, message: 'Введите e-mail' }]}>
              <Input placeholder={emailPlaceholder} />
            </Form.Item>
          </div>
          <div className="remind-password-form__control">
            <Button
              className="remind-password__control-submit"
              loading={changePassword.loading}
              size="large"
              htmlType="submit"
              type="primary"
            >
              Восстановить пароль
            </Button>
          </div>
        </Form>
      )}
      {changePassword.data && (
        <p>Мы отправили письмо с инструкцией по изменению пароля на указанную электронную почту.</p>
      )}
    </div>
  );
};

export default SignFormContainer;
