import AuthToken from '@/helpers/authToken';

import AuthenticatedResource from './authenticatedResource';

export default class AuthResource extends AuthenticatedResource {
  authToken = null;

  pk() {
    return 'auth';
  }

  static createShape() {
    const superShape = super.createShape();

    return {
      ...superShape,
      fetch: async (params, body) => {
        const remember = params.remember;
        delete params.remember;

        const response = await superShape.fetch(params, body);

        const { authToken } = response;

        AuthToken.saveToken(authToken, !remember);

        this.updateUser();

        return { authToken };
      }
    };
  }

  static deleteShape() {
    const superShape = super.deleteShape();

    return {
      ...superShape,
      fetch: async (params, body) => {
        await superShape.fetch(params, body);

        AuthToken.removeToken();

        this.updateUser();

        return {};
      }
    };
  }

  static urlRoot = `${CONFIG.apiBaseUrl}/auth`;
}
