import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import { createContext, useReducer } from 'react';

// Don't load from index's namespace to avoid the cycle dependencies
import SignForm from '@/containers/signForm';

const globalContext = createContext();
const { Provider } = globalContext;

const initialState = {
  currentModal: null,
  loading: false
};

Spin.setDefaultIndicator(<LoadingOutlined />);

export const GlobalProvider = ({ children }) => {
  const [loading, setLoading] = useReducer((_, value) => value, initialState.loading);
  const [currentModal, setModal] = useReducer((_, value) => value, initialState.loading);

  const openModal = (type) => setModal(type);
  const closeModal = () => setModal(null);

  return (
    <Provider value={{ loading, setLoading, openModal, closeModal }}>
      <Spin className="global-spin" spinning={loading}>
        {children}
      </Spin>
      <Modal
        centered
        className="login-modal"
        destroyOnClose
        footer={null}
        onCancel={closeModal}
        visible={Boolean(currentModal)}
        width={540}
      >
        <SignForm form={currentModal} setLoading={setLoading} />
      </Modal>
    </Provider>
  );
};

export default globalContext;
